import "@glidejs/glide/dist/glide.min";
import Glide from "@glidejs/glide";

window.addEventListener("load", () => {
  if (document.querySelector(".glide-home-news")) {
    const glide = new Glide(".glide-home-news", {
      type: "slider",
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
    });
    glide.mount();
  }

  if (document.querySelector(".glide-house-images")) {
    const glide2 = new Glide(".glide-house-images", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
      gap: 35,
    });

    glide2.mount();
  }

  if (document.querySelector('.glide-carrousel')) {
    document.querySelectorAll('.glide-carrousel').forEach(element => {
      const glideCarrousel = new Glide(element, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        swipeThreshold: 10,
        gap: 70,
        peek: {
          before: 520,
          after: 520,
        },
        breakpoints: {
          320: {
            gap: 13,
            perView: 1,
            peek: {
              before: 23,
              after: 25,
            },
          },
          546: {
            gap: 13,
            perView: 1,
            peek: {
              before: 35,
              after: 35,
            },
          },
          992: {
            gap: 20,
            perView: 1,
            peek: {
              before: 75,
              after: 75,
            },
          },
          1200: {
            gap: 50,
            perView: 1,
            peek: {
              before: 250,
              after: 250,
            },
          },
          1600: {
            gap: 50,
            perView: 1,
            peek: {
              before: 350,
              after: 350,
            },
          },
          1800: {
            gap: 60,
            perView: 1,
            peek: {
              before: 450,
              after: 450,
            },
          },
        },
      });

      glideCarrousel.on(['mount.after'], () => {
        placeArrows(element);
      });

      glideCarrousel.mount();
    });
  }

  function placeArrows(elementSelector) {
    var targetDiv = elementSelector.getElementsByClassName("glide__slide--active")[0].getElementsByTagName("img")[0];
    var rect = targetDiv.getBoundingClientRect();
    $(elementSelector).find(".achievements-carousel-control-left").css("left", rect.left - 30 + 'px');
    $(elementSelector).find(".achievements-carousel-control-left").css("top", ((rect.bottom - rect.top) / 2) - 30) + 'px';
    $(elementSelector).find(".achievements-carousel-control-right").css("left", rect.right - 30 + 'px');
    $(elementSelector).find(".achievements-carousel-control-right").css("top", ((rect.bottom - rect.top) / 2)  - 30) + 'px';
  }

  $(".blueprints-modal").on("focus", function () {
    const blueprintSlideElement = $(this).find(
      ".glide-house-blueprints:not(.glide--slider)"
    );

    if (blueprintSlideElement) {
      const glide3 = new Glide(blueprintSlideElement[0], {
        gap: 0,
        type: "slider",
        startAt: 0,
        perView: 1,
        swipeThreshold: 5,
      });

        const glideArrows = document.querySelectorAll(
          '.modal-house-carousel-control div'
        );
        glideArrows.forEach(function (glideArrow) {
          glideArrow.addEventListener('click', function () {
            glide3.go(glideArrow.dataset.glideDir);
          });
        });
      glide3.mount();
    }
  });

  if (document.querySelector(".glide-announcing-plot")) {
    const glide4 = new Glide(".glide-announcing-plot", {
      type: "slider",
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
    });
    glide4.mount();
  }

  function resplaceAllArrows() {
    if ($('.glide-carrousel').length) {
      placeArrows('.glide-carrousel');
    }
  }

  $(document).ready(function() {
    window.onresize = resplaceAllArrows;

    function adjustHeight() {
      $('.container-middle-carrousel').each(function(){
        let trackHeight = $(this).find('.slider-middle-carrousel').innerHeight();
        $(this).height(trackHeight + 30);
      });
    }

    setTimeout(function(){
      adjustHeight();
    }, 350);

    $(window).resize(function(){
        adjustHeight();
    });
  });
});
