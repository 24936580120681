$(document).ready(function($) {
  $('.home-hero-thumbnail-slider').ready(function() {
    $(function(){
      $(".home-hero-main-slider").slick({
        lazyLoad: 'progressive',
        autoplay: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        cssEase: 'ease-in-out',
        swipe: true,
        fade: false,
        asNavFor: ".home-hero-thumbnail-slider",
      });
      $(".home-hero-thumbnail-slider").slick({
        lazyLoad: 'progressive',
        autoplay: true,
        infinite: true,
        slidesToShow: 5,
        dots: true,
        focusOnSelect: true,
        variableWidth: false,
        asNavFor: ".home-hero-main-slider",
      });
    });

    // $('.home-hero-thumbnail-slider').slick('setPosition');
  });
});

/**
 * La fonction suivante s'assure que toutes les cartes du slider en hero de la home aient la même hauteur.
 */

$(document).ready(function() {
  function adjustHeights() {
      // Réinitialise la hauteur pour permettre le recalcul
      $('.home-hero-thumbnail-slider-infos').css('height', '');

      // Trouve la hauteur maximale
      var maxHeight = 0;
      $('.home-hero-thumbnail-slider-infos').each(function() {
          var thisHeight = $(this).height();
          if (thisHeight > maxHeight) {
              maxHeight = thisHeight;
          }
      });

      // Affecte la hauteur maximale à tous les éléments
      $('.home-hero-thumbnail-slider-infos').height(maxHeight);
  }

  // Ajuste les hauteurs initialement
  adjustHeights();

  // Met à jour les hauteurs lors d'un redimensionnement de la fenêtre
  $(window).resize(function() {
      adjustHeights();
  });

});
