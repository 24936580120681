// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/scrollspy';

import 'slick-carousel';

import './custom';
import './glide';
import './slide';
import './loan-simulator';
import './video';
import './fancybox';
import './copy';
import './share';
