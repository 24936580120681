class LoanSimulator {
  constructor() {
    this.rangesliderInterestRate = $('#rangesliderInterestRate');
    this.rangesliderLoanAmount = $('#rangesliderLoanAmount');
    this.rangesliderLoanDuration = $('#rangesliderLoanDuration');
    this.simulatorTagInterestRate = $('#simulatorTagInterestRate');
    this.simulatorTagLoanAmount = $('#simulatorTagLoanAmount');
    this.simulatorTagLoanDuration = $('#simulatorTagLoanduration');
    this.rangesliderResult = $('#rangesliderResult');
  }

  init() {
    this.initializeSliders();
    this.attachInputListeners();
  }

  computeInstallments() {
    const t = ((2 + ((parseFloat(this.rangesliderInterestRate.val()*3)) / 100)) / 100).toFixed(8);
    const K = parseInt(this.rangesliderLoanAmount.val());
    const n = parseInt(this.rangesliderLoanDuration.val()) * 12;
    const m = (K * (t / 12)) / (1 - Math.pow(1 + (t / 12), -1 * n));

    this.rangesliderResult.text('Mes mensualités : ' + parseInt(m).toLocaleString() + ' €');
  }

  initializeSimulatorTagInterestRatePosition() {
    const position = ((this.rangesliderInterestRate.val() * this.rangesliderInterestRate.width()) / 100)
      - ((100 - this.rangesliderInterestRate.val()) * this.simulatorTagInterestRate.width() / 200)
      - (this.simulatorTagInterestRate.width() * this.rangesliderInterestRate.val() / 100)
      - (6 * (100 - this.rangesliderInterestRate.val()) / 100);
    this.simulatorTagInterestRate.css('left', position);

    const position_slider = this.rangesliderInterestRate.val().toString() + "% 100%";
    $('#rangesliderInterestRate').css('background-size', position_slider);
  }

  initializeSimulatorTagLoanAmount() {
    const position = ((this.rangesliderLoanAmount.val() / 500000 * this.rangesliderLoanAmount.width()))
      - 10 * (this.rangesliderLoanAmount.val() / 500000) - this.simulatorTagLoanAmount.width() + 20;
    this.simulatorTagLoanAmount.css('left', position);

    const position_slider = (this.rangesliderLoanAmount.val() / 5000).toString() + "% 100%";
    $('#rangesliderLoanAmount').css('background-size', position_slider);
  }

  initializeSimulatorTagLoanDuration() {
    const position = (((this.rangesliderLoanDuration.val() - 6) / 20 * this.rangesliderLoanDuration.width())) - 17 * ((this.rangesliderLoanDuration.val() - 5) / 20);
    this.simulatorTagLoanDuration.css('left', position);

    const position_slider = ((this.rangesliderLoanDuration.val() - 5) * 5 ).toString() + "% 100%";
    $('#rangesliderLoanDuration').css('background-size', position_slider);
  }

  initializeSliders() {
    this.initializeSimulatorTagInterestRatePosition();
    this.initializeSimulatorTagLoanAmount();
    this.initializeSimulatorTagLoanDuration();
  }

  attachInputListeners() {
    this.rangesliderInterestRate.on('input', () => {
      this.initializeSimulatorTagInterestRatePosition();
      this.simulatorTagInterestRate.text((2 + (this.rangesliderInterestRate.val() / 33.33)).toFixed(2) + '%');
      this.computeInstallments();
    });

    this.rangesliderLoanAmount.on('input', () => {
      this.initializeSimulatorTagLoanAmount();
      this.simulatorTagLoanAmount.text(parseInt(this.rangesliderLoanAmount.val()).toLocaleString() + '€');
      this.computeInstallments();
    });

    this.rangesliderLoanDuration.on('input', () => {
      this.initializeSimulatorTagLoanDuration();
      this.simulatorTagLoanDuration.text(parseInt(this.rangesliderLoanDuration.val()).toLocaleString() + ' ans');
      this.computeInstallments();
    });
  }
}

$(document).ready(function () {
  if($('#rangesliders').length){
    const loanSimulator = new LoanSimulator();
    loanSimulator.init();

    $(document).on('click', '#id-mobile-loan-firstblock-button', function ($event) {
      $event.preventDefault();
      $('.mobile-loan-firstblock').toggleClass('unhide-mobile-loan');
      $(this).toggleClass('open');
    });

    $(document).on('click', '#id-mobile-loan-secondblock-button', function ($event) {
      $event.preventDefault();
      $('.mobile-loan-secondblock').toggleClass('unhide-mobile-loan');
      $(this).toggleClass('open');
      loanSimulator.initializeSliders();
    });
  }
});
