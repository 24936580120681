$(document).on('click', '#search-filters-button', function($event) {
  $event.preventDefault();
  $('#search-filters').toggleClass('search-filters-active');
  const btnReset = $('.btn-reset');
  if (btnReset.length) {
    btnReset.toggleClass('btn-reset-pushed');
  }
});

jQuery(document).ready(function($) {
  //restrict number phone form
  $('input[type="tel"]').on('input', function() {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });

  //restrict number zip form
  $('.zip input[type="text"]').on('input', function() {
    $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });
});

// dropdown-search-mobile
document.addEventListener('DOMContentLoaded', function() {
  var dropdownMenu = document.querySelector('.dropdown-search-mobile');

  if (dropdownMenu) {
    dropdownMenu.addEventListener('click', function(event) {
      event.stopPropagation();
    });
  }
});

//collapse text retail house
jQuery(function ($) {
  var box = $(".house-body-description-description-text");
  var minimumHeight = 300; // max height in pixels
  var initialHeight = box.innerHeight();
  // reduce the text if it's longer than 320px
  if (initialHeight > minimumHeight) {
      box.css('height', minimumHeight);
      $(".read-more-button").show();
  }

  SliderReadMore();

  function SliderReadMore() {
    $(".slide-read-more-button-house").on('click', function () {
      // get current height
      var currentHeight = box.innerHeight();

      // get height with auto applied
      var autoHeight = box.css('height', 'auto').innerHeight();

      // reset height and revert to original if current and auto are equal
      var newHeight = (currentHeight | 0) === (autoHeight | 0) ? minimumHeight : autoHeight;

      box.css('height', currentHeight).animate({
        height: (newHeight),
      });

      $(".slide-read-more-button-house").toggle();
    });
  }
});

//collapse text retail house with plot
jQuery(function ($) {
  var box = $(".retail-house-with-plot-left-text");
  var minimumHeight = 290; // max height in pixels
  var initialHeight = box.innerHeight();
  // reduce the text if it's longer than 320px
  if (initialHeight > minimumHeight) {
      box.css('height', minimumHeight);
      $(".read-more-button").show();
  }

  SliderReadMore();

  function SliderReadMore() {
    $(".slide-read-more-button-house-with-plot").on('click', function () {
      // get current height
      var currentHeight = box.innerHeight();

      // get height with auto applied
      var autoHeight = box.css('height', 'auto').innerHeight();

      // reset height and revert to original if current and auto are equal
      var newHeight = (currentHeight | 0) === (autoHeight | 0) ? minimumHeight : autoHeight;

      box.css('height', currentHeight).animate({
        height: (newHeight),
      });

      $(".slide-read-more-button-house-with-plot").toggle();
    });
  }
});

// Toggle entre les extraits et les descriptions partielles
const descriptionContent = $('#descriptionContent');
let truncatedDescription = descriptionContent.html();

$(document).on('click', '#toggleDescriptionButton', function() {
  if ($(this).data('active') == '0') {
    // Sauvegarde la description tronquée actuelle
    truncatedDescription = descriptionContent.html();
    // Affiche la description complète
    const fullDescription = descriptionContent.data('full-description');
    descriptionContent.html(fullDescription);
    // Change le texte du bouton
    $(this).text('Lire moins');
    $(this).data('active', '1');
  } else {
    // Affiche la description tronquée
    descriptionContent.html(truncatedDescription);
    // Change le texte du bouton
    $(this).text('Lire la suite');
    $(this).data('active', '0');
  }
});

//restrict number phone form
jQuery(document).ready(function($) {
  $('input[type="tel"]').on('input', function() {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });
});

//add class scroll for box-shadow
window.addEventListener("load", () => {
  let isNavScrolling = false;

  navScroll();

  function navScroll() {
      const nav = document.querySelector(".ch-header-secondrow-desktop");
      const threshold = 10;

      if(nav) {
        if (window.scrollY > threshold && !isNavScrolling) {
            nav.classList.add('nav-scrolling');
            isNavScrolling = true;
        } else if (window.scrollY <= threshold && isNavScrolling) {
            nav.classList.remove('nav-scrolling');
            isNavScrolling = false;
        }
      }
  }

  window.addEventListener("scroll", navScroll);
});

$(document).ready(function(){
  let originalPlaceholder = '';

  // Quand le champ de recherche est sélectionné (focus)
  $(document).on('focus', '.search-field', function(){
      // Stocke le placeholder actuel
      originalPlaceholder = $(this).attr('placeholder');
      // Efface le texte du placeholder
      $(this).attr('placeholder', '');
  });

  // Quand le champ de recherche perd le focus
  $(document).on('blur', '.search-field', function(){
      // Si le champ est vide, rétablit le placeholder original
      if($(this).val() == '') {
          $(this).attr('placeholder', originalPlaceholder);
      }
  });
});
