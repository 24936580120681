// Charge la vidéo du bloc Video de la page d'accueil
// au clic du bouton lecture
$(function() {
  $(document).on('click', '#home-video-player', function(ev) {
    if ( $( "#ch-achievement-player" ).length ) {
      const iframe = $('#ch-achievement-player');
      if (iframe.length) {
        let videoUrl = iframe.attr('data-src');
        iframe.attr('src', videoUrl + '?rel=0&controls=1&showinfo=0&autoplay=1&mute=1');
        ev.preventDefault();
        iframe.css('zIndex', '2');
      }
    } else if ( $( "#ch-visit-iframe" ).length ) {
      const iframe = $('#ch-visit-iframe');
      iframe.attr("src", iframe.data("src"));
      ev.preventDefault();
      iframe.css('zIndex', '2');
    }
  });
});
