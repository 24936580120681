window.addEventListener('load', () => {
    // Share on mobile
    const shareData = {
      title: document.querySelector('h1').innerText,
      url: window.location.href,
    };

    const btn = document.querySelector('#shareButton');

    if(btn) {
      btn.addEventListener('click', () => {
        navigator.share(shareData).then(() => {});
      });
    }
  });
