document.addEventListener('DOMContentLoaded', function () {
  if (window.innerWidth > 992) {
    let copyBtn = document.getElementById('copyPasteButton');
    if (copyBtn) {
      copyBtn.addEventListener('click', copyFunction);
    }
  }

  function copyFunction() {
    let url = document.getElementById('copyBtnUrl').value;
    let msgSuccess = document.querySelector('.share-copied-success');

    navigator.clipboard.writeText(url);
    msgSuccess.style.display = 'block';

    // Hide msg after 2s
    setTimeout(function() {
      msgSuccess.style.display = 'none';
    }, 1500);
  }
});
